import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDOJ_E1wpmdXD-qkgQDaN-DRHII-N8bS4c",
    authDomain: "personal-backend-1c930.firebaseapp.com",
    projectId: "personal-backend-1c930",
    storageBucket: "personal-backend-1c930.appspot.com",
    messagingSenderId: "67130431223",
    appId: "1:67130431223:web:50d67c29859748a117e5f2"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

if(window.location.hostname === 'localhost'){
  connectAuthEmulator(auth, "http://localhost:9099");
}

// if()
// auth.useEmulator("http://127.0.0.1:4000/auth ")

export { auth };