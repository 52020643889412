import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        //Here you can place the code that you want to run if the user is logged in
        setIsLoaded(true)
      } else {
        window.location.href = "/";
      }
      
    });
  });

  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const signOutClick = () => {
    auth.signOut();
    navigate("/");
  };
  return !isLoaded ? (
    <CircularProgress />
  ) : (
    <>
      <div className="word">
        <h2>Welcome:{user?.displayName}</h2>
        <button onClick={() => signOutClick()}>Signout </button>
      </div>
    </>
  );
}

export default Home;
